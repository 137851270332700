<template>
    <admin-dashboard-layout>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="stores"
            :items-per-page="5"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at }} <!--TODO add day.js and default format -->
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import Store from "../../../models/Store";

    export default {
        name: "stores-index",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                loading: false,
                stores: [],
                headers: [
                    {
                        text: '# ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    { text: 'Name', value: 'name' },
                    { text: 'Created at', value: 'created_at' },
                ],
            }
        },
        methods: {
            handleClickRow(store) {
                console.log(store)
                this.$router.push({name: 'admin.stores.show', params: {id: store.id}})
            }
        },
        async mounted() {
            this.loading = true
            this.stores = await Store.get()
            this.loading = false
        },
    }
</script>

<style scoped>

</style>
